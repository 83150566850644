import React, {useEffect, useCallback } from "react"
import { graphql } from 'gatsby';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import useViewport from '../utils/useViewport'
import {
  moveLines,
  imageReveal,
  revealText,
  coverReveal } from "../animations/Animate"
import { useData } from '../utils/DataProvider'
import LayoutInner from "../components/LayoutInner"
import SEO from "../components/Seo"
import HomeHero from "../components/Home/Hero"
import Section2 from "../components/Home/Section2";
import Section3 from "../components/Home/Section3a";
import ImageText from "../components/ImageText";
import Vote from "../components/Home/Vote";

gsap.registerPlugin(ScrollTrigger);

const tl = gsap.timeline({defaults: {ease: "power2.easeInOut"}});

const IndexPage = ({data, location}) => {
  const [state, setState] = useData()

  const {width} =  useViewport();
  const homeData = data.homeQuery;
  const heroData = homeData.heroQuery;

  useEffect(() => {
    setState({ ...state, color: homeData.meta.color});
  }, [setState, homeData]);

  const heroAnimation = useCallback((width) => {
    if (width > 800) {
      gsap.from('.jsSlider', {
        scaleX: 0,
        duration: 1,
        transformOrigin:"left",
        ease: "power4.easeInOut"
      })
    } else {
      gsap.from('.jsSlider', {
        scaleY: 0,
        duration: 1,
        transformOrigin:"top",
        ease: "power2.easeInOut",
      })
    }
    gsap.from('.jsSlider1', { opacity: 0, delay: 1 })
    gsap.from('.jsLogo', { opacity: 0, duration: 0.5, delay: 1 })
    gsap.from('.jsHeaderMenu', {
        duration: .5,
        x: 0,
        scaleX: 0,
        delay: 1,
        transformOrigin:"left center",
        stagger: 0.3,
      })

      // DISABLED per client request
      // revealText('.jsHeadline .ui-block__text')
      gsap.from('.jsFeatureHero', {
        duration: 1,
        opacity:0,
        delay: .5,
        x: 40,
      })

      // DISABLED per client request
      // gsap.to('.jsHeadline', {
      //   opacity: 0,
      //   y: -200,
      //   scale: 1.2,
      //   scrollTrigger: {
      //     trigger: '.jsSectionImageText',
      //     start: '-60%',
      //     end: 0,
      //     scrub: true,
      //   }
      // });
  })

  const section1Animation = useCallback(() => {
    moveLines()
    coverReveal()
    // DISABLED per client request
    // revealText('.jsTitleLines .ui-block__text')
  })

  const section2Animation = useCallback(() => {
    gsap.to('.jsSectionHome2', {
      scrollTrigger: {
        trigger: '.jsSectionHome2',
        toggleClass: 'active',
        toggleActions: 'play reverse play reverse',
      }
    })
    imageReveal('.jsTwoHImg')

    // DISABLED per client request

    // gsap.from(['.jsTwoH2 p', '.jsTwoH2 span'], {
    //   duration: 1,
    //   opacity: 0,
    //   y: 100,
    //   ease: "power3.easeOut",
    //   stagger: 0.3,
    //   scrollTrigger: {
    //     trigger: '.jsTwoH2',
    //     start: "top 100%",
    //     toggleActions: 'play reverse play reverse',
    //   }
    // })
  })

  const section3Animation = useCallback((width, lines) => {
    moveLines(`-${(width / 2) + lines}px`, '.jsMoveLinesSection3', '.jsSectionHome3', )
    imageReveal('.jsTriHImg1')
    imageReveal('.jsTriHImg2')
    imageReveal('.jsTriHImg3')

    // DISABLED per client request

    // gsap.from('.jsTriHContentTop', {
    //   y: 50,
    //   autoAlpha: 0,
    //   duration: .7,
    //   ease: "power3.easeOut",
    //   scrollTrigger: {
    //     trigger: '.jsTriHContentTop',
    //     start: 'top 80%',
    //     toggleActions: 'play reverse play reverse',
    //   }
    // })

    // DISABLED per client request

    // gsap.from('.jsTriHContentBottom', {
    //   y: 100,
    //   autoAlpha: 0,
    //   duration: 1,
    //   ease: "power3.easeOut",
    //   scrollTrigger: {
    //     trigger: '.jsTriHContentBottom',
    //     start: 'top 80%',
    //   }
    // })

  })

  useEffect(() => {
    heroAnimation(width);
    section1Animation();
    section2Animation(width);
    section3Animation(width, state.linesWidth);

  }, [])

  return (
    <LayoutInner location={location}>
      <SEO title={homeData.meta.title} />
      <HomeHero data={heroData} />
      {state.vote && <Vote />}
      <ImageText data={homeData.sectionsQuery.section1} order={'image,content'}  />
      <Section2 data={homeData.sectionsQuery.section2}/>
      <Section3 data={homeData.sectionsQuery.section3} />
    </LayoutInner>
  )
}

export default IndexPage

export const query = graphql`
  query Index {
    homeQuery: homePageJson {
      meta {
        title
        shareImage
        keywords
        color
      }
      heroQuery: homeHero {
        title
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      sectionsQuery: sections {
        section1 {
          content
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section2 {
          content
          name
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section3 {
          content
          description
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

