import React from "react";
import VoteImg from "../../images/nominee.png";
import "./Vote.scss";

const Vote = () => (
  <a href="https://www.awwwards.com/sites/the-offices-by-square-one" className="vote" target="_blank" rel="noopener noreferrer">
    <img src={VoteImg} />
  </a>
);

export default Vote;