import React from "react";
import PropTypes from "prop-types";
import Section from "../Section";
import Image from "../Image";

import "./Section2.scss";

const Section2 = ({ data }) => {
  return (
    <Section className="two-h jsSectionHome2">
      <div className="section__stripes-2h jsSectionStripes"></div>
      <div className="module2">
        <div className="module2__content">
          <blockquote className="blockquote jsTwoH2">
            <p>"{data.content}"</p>
            <span className="blockquote__name">{data.name}</span>
          </blockquote>
        </div>
        <div className="module2__image">
          <Image
            className="jsTwoHImg"
            data={data.image}
          />
        </div>
      </div>
    </Section>
  );
};

Section2.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.object
  }),
};

export default Section2;
