import React from "react";
import PropTypes from "prop-types";
import Section from "../Section";
import Lines from "../Lines";
import Image from "../Image";

import "./Section3a.scss";

const Section3 = ({ data }) => (
  <Section className="tri jsSectionHome3">
    <Lines name="Section3" />
    <div className="module3 module--tri">
      <div className="div1 jsTriHome">
        <Image
          className="jsTriHImg1"
          data={data.image1}
        />
      </div>
      <div className="div2 jsTriHome">
        <div className="text-w-border jsTriHContentTop">{data.content}</div>
      </div>

      <div className="div3 jsTriHome">
        <Image
          className="jsTriHImg2"
          data={data.image2}
        />
      </div>

      <div className="div4 jsTriHome">
        <Image
          className="jsTriHImg3"
          data={data.image3}
        />
      </div>

      <div className="div5 jsTriHome">
        <div className="jsTriHContentBottom">{data.description}</div>
      </div>
    </div>
  </Section>
);

Section3.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.object,
    image2: PropTypes.object,
    image3: PropTypes.object,
  }),
};

export default Section3;
