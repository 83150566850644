import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import { useData } from "../../utils/DataProvider";
import Section from "../Section";

import "./Hero.scss";

const HomeHero = ({ data }) => {
  const image = getImage(data.image)
  const [state, setState] = useData();
  const { ref, inView } = useInView({
    rootMargin: "-100px 0px",
    threshold: 0,
  });

  useEffect(() => {
    setState(state => ({
      ...state,
      isFixed: !inView,
    }));
  }, [inView]);
  return (
    <Section className="home-hero" forwardedRef={ref} data-visible={inView}>
      <div className="home-hero__wrap">
        <div className="title__wrap">
          <h1
            className="headline jsHeadline"
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          ></h1>
        </div>
        <div className="feature-hero ">
          <GatsbyImage image={image} alt="" className="feature-hero__image full__image jsFeatureHero" />
        </div>
        <div className="slider jsSlider"></div>
        <div className="slider1 jsSlider1"></div>
      </div>
    </Section>
  );
};

HomeHero.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      html: PropTypes.string,
    }),
    image: PropTypes.object,
  }),
};

export default HomeHero;
